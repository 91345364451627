.blockquote {
  margin: 0;
  padding: 0;

  cite {
    font-style: normal;
  }
}

.blockquoteText {
  font-weight: 300;
  font-style: italic;
  font-size: 32px;
  line-height: 1.25;
}